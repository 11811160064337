<template>
  <div>
    <v-app-bar app elevate-on-scroll clipped-left dark color="primary">
      <v-app-bar-nav-icon v-if="isLoggedIn" @click.stop="drawer = !drawer" />
      <v-toolbar-title class="">
        <span class="font-weight-light">Portasigma</span>
      </v-toolbar-title>
      <v-spacer />
      <!--router-link :to="{ name: 'home' }">
        <v-btn v-if="!isLoggedIn" text right>
          <v-icon left>mdi-login-variant</v-icon>Login
        </v-btn>
      </router-link-->
      <router-link :to="{ name: 'home' }">
        <v-btn v-if="currentUser" text right small @click="doLogout">
          <v-icon left>mdi-logout-variant</v-icon>Logout
          {{ currentUser.username }}
        </v-btn>
      </router-link>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" v-if="currentUser" app clipped dark>
      <v-list>
        <v-list-item link :to="{ name: 'home' }">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="item in allowedItems"
          :key="item.text"
          link
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  data: () => ({
    drawer: false,
    items: [
      { text: "Users", icon: "mdi-account-group", route: { name: "users" } },
      {
        text: "Associations",
        icon: "mdi-domain",
        route: { name: "associations" }
      },
      {
        text: "Pricing Plans",
        icon: "mdi-cash",
        route: { name: "plans" }
      }
    ]
  }),
  computed: {
    ...mapState("accounts", ["credentials", "currentUser"]),
    ...mapGetters("accounts", ["isLoggedIn"]),
    allowedItems() {
      return this.items.filter(item => this.isLoggedIn || !item.auth);
    }
  },
  created() {
    if (this.isLoggedIn) {
      // TODO: find a better place for this, e.g. the store
      // ensure the current user data is loaded
      this.login(this.credentials);
    }
  },
  methods: {
    ...mapActions("accounts", ["logout", "login"]),
    doLogout() {
      this.logout();
      this.$router.push({ name: "login" });
      this.drawer = false;
    }
  }
};
</script>
