import { render, staticRenderFns } from "./BannerLogo.vue?vue&type=template&id=0fbf2740&scoped=true&functional=true&"
var script = {}
import style0 from "./BannerLogo.vue?vue&type=style&index=0&id=0fbf2740&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "0fbf2740",
  null
  
)

export default component.exports