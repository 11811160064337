<template>
  <v-app id="app" color="grey lighten-5">
    <NavBar />

    <v-main class="ma-5">
      <router-view></router-view>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  props: {
    source: String
  },
  components: {
    Footer,
    NavBar
  },
  computed: {
    showDrawer() {
      return this.drawer;
    }
  }
};
</script>
<style scoped>
#app {
  background: linear-gradient(
    to bottom,
    rgba(176, 183, 185, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>
