<template>
  <div role="tabpanel" class="tab-pane active" id="certificado">
    <h2>Selecciona tu certificado</h2>

    <div class="caja">
      <ul>
        <li v-for="(certificate, index) in filteredCertificates" :key="index">
          <a v-if="certificate.valid" title="certificado">
            <span class="linea"
              ><span>Sujeto:</span> {{ certificate.subject }}</span
            >
            <span class="linea"
              ><span>Emisor:</span> {{ certificate.issuer }}</span
            >
            <span class="linea"><span>ID:</span> {{ certificate.id }}</span>
          </a>
          <div v-else class="novalid">
            <span class="linea"
              ><span>Sujeto:</span> {{ certificate.subject }}</span
            >
            <span class="linea"
              ><span>Emisor:</span> {{ certificate.issuer }}</span
            >
            <span class="linea"><span>ID:</span> {{ certificate.id }}</span>
          </div>
        </li>
      </ul>

      <div class="loading">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <p>
          Buscando certificados disponibles en el dispositivo, por favor
          espere...
        </p>
      </div>
    </div>

    <div class="formulario clearfix">
      <form>
        <div class="checkbox">
          <input type="checkbox" id="validos" v-model="onlyValid" />
          <label for="validos"
            >Mostrar sólo los certificados válidos en PortaSigma</label
          >
        </div>
        <button @click.prevent="loadCertificates" class="refresh">
          <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
      </form>
    </div>

    <p class="version">
      Versión del servicio de validación (ISM); 10.6.0-snapshot
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      onlyValid: false,
      certificates: [
        {
          valid: true,
          subject: "ROMÁN DE BLAS RICART",
          issuer: "EC-CIUTADANIA",
          id: "ES/69696969H"
        },
        {
          valid: false,
          subject: "IMPOSTOR PÉREZ",
          issuer: "EC-CIUTADANIA",
          id: "ES/12345678Z"
        }
      ]
    };
  },
  computed: {
    filteredCertificates() {
      if (this.onlyValid) {
        return this.certificates.filter(cert => cert.valid);
      } else {
        return this.certificates;
      }
    }
  },
  methods: {
    loadCertificates() {
      // TODO: ...
    }
  }
};
</script>
