import Backend from "@/services/Backend";

const getCredentialsFromLocalStorage = () => {
  let storedCredentials = localStorage.getItem("creds");
  if (storedCredentials) {
    storedCredentials = JSON.parse(storedCredentials);
  }
  return storedCredentials;
};

export const namespaced = true;

export const state = {
  credentials: getCredentialsFromLocalStorage(),
  currentUser: null
};

export const getters = {
  isLoggedIn(state) {
    return state.credentials != null;
  }
};

export const mutations = {
  SET_CREDENTIALS(state, credentials) {
    state.credentials = credentials;
    if (credentials == null) {
      localStorage.removeItem("creds");
    } else {
      localStorage.setItem("creds", JSON.stringify(credentials));
    }
  },
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  }
};

export const actions = {
  // global
  init: {
    root: true,
    async handler({ commit, dispatch }) {
      const credentials = getCredentialsFromLocalStorage();
      commit("SET_CREDENTIALS", credentials);
      await dispatch("login", credentials);
    }
  },
  // namespaced
  async login({ commit }, credentials) {
    commit("SET_CREDENTIALS", credentials);
    try {
      let response = await Backend.whoami();
      commit("SET_CURRENT_USER", response.data);
      return true;
    } catch (e) {
      commit("SET_CREDENTIALS", null);
      return false;
    }
  },
  logout({ commit }) {
    commit("SET_CREDENTIALS", null);
    commit("SET_CURRENT_USER", null);
    localStorage.removeItem("creds");
  }
};
