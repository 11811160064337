export const IS_LOCALHOST =
  ["localhost", "127.0.0.1", "192.168.1."].findIndex(x =>
    window.location.hostname.startsWith(x)
  ) !== -1;

export const BASE_URL = IS_LOCALHOST
  ? "//localhost:8901"
  : "//apiv2.portasigma.com/accounts";

export const API_TIMEOUT = IS_LOCALHOST ? 10000 : 20000;
