import axios from "axios";
import * as settings from "./settings";

// TODO: find a better way to do environment-specific settings?
// TODO: resiliency patterns - https://github.com/softonic/axios-retry
// rather than increased timeouts
const backend = axios.create({
  baseURL: settings.BASE_URL,
  timeout: settings.API_TIMEOUT,
  withCredentials: false
  // headers: {
  //   Accept: "application/json",
  //   "Content-Type": "application/json"
  // }
});

const get = function(url) {
  const auth = JSON.parse(localStorage.getItem("creds"));
  return backend.get(url, { auth });
};

const put = function(url, data) {
  const auth = JSON.parse(localStorage.getItem("creds"));
  return backend.put(url, data, { auth });
};

export default {
  whoami() {
    return get("/users/whoami");
  },
  getAssociations(query) {
    return get(`/associations/?q=${query}`);
  },
  getAssociation(id) {
    return get(`/associations/${id}`);
  },
  getAssociationUsers(association) {
    return get(`/associations/${association.id}/users`);
  },
  getAssociationPricingPlans(association) {
    return get(`/associations/${association.id}/plans`);
  },
  getUsers(query, pageSize) {
    return get(`/users/?q=${query}&n=${pageSize}`);
  },
  getUser(id) {
    return get(`/users/${id}`);
  },
  getUserAssociations(user) {
    return get(`/users/${user.id}/associations`);
  },
  getUserPricingPlan(user) {
    return get(`/users/${user.id}/plan`);
  },
  getUserSignatures(user) {
    return get(`/users/${user.id}/signatures`);
  },
  putUser(id, user) {
    return put(`/users/${id}`, user);
  },
  putUserRoles(id, roleNames) {
    return put(`/users/${id}/roles`, roleNames);
  },
  getRoles() {
    return get("/roles");
  },
  getPricingPlans() {
    return get(`/pricing-plans?size=500`);
  },
  getStats() {
    return get("/stats");
  }
};
