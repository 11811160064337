export const namespaced = true;

export const state = {
  nextRoute: null
};

export const getters = {
  nextRoute(state) {
    let route = state.nextRoute;
    if (!route) {
      route = "/";
    }
    return route;
  }
};

export const mutations = {
  SET_NEXT(state, nextRoute) {
    state.nextRoute = nextRoute;
  }
};

export const actions = {
  setNextRoute({ commit }, route) {
    commit("SET_NEXT", route);
  }
};
