<template>
  <div>
    <v-alert type="error" v-show="!success">
      Login failed, please try again.
    </v-alert>

    <v-form dark class="white--text" @submit.prevent="submit">
      <v-card width="480" class="mx-auto py-8 px-4 px-sm-12" color="primary">
        <v-card-title dark>
          <h3 class="text-h5 white--text">
            Please Login
          </h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            dark
            required
            v-model="username"
            name="username"
            label="Username or e-mail"
            prepend-icon="mdi-account-circle"
          />
          <v-text-field
            dark
            required
            v-model="password"
            name="password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" color="secondary" class="mx-auto">
            <span>Login</span>
            <v-icon right>mdi-login-variant</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-form dark class="white--text" @submit.prevent="login" v-if="csvEnabled">
      <v-card width="480" class="mx-auto my-8 px-12" color="primary">
        <v-card-title dark>
          <p>¿Tienes un código de validación en papel?</p>
        </v-card-title>
        <v-card-text>
          <v-text-field
            type="text"
            class="form-control"
            id="codigo"
            placeholder="Ingresa el código impreso"
          />
          <v-btn type="submit" color="secondary" class="mx-auto">OK</v-btn>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginUsername",
  data() {
    return {
      showPassword: false,
      csvEnabled: false,
      username: "",
      password: "",
      success: true
    };
  },
  methods: {
    ...mapActions("accounts", ["login"]),
    ...mapGetters("routing", ["nextRoute"]),
    async submit() {
      this.$emit("loggedIn");
      this.success = await this.login({
        username: this.username,
        password: this.password
      });
      if (this.success) {
        this.$router.push(this.nextRoute());
      }
    }
  }
};
</script>
<style scoped>
.v-btn {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
}
</style>
