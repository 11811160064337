<template>
  <v-container class="home">
    <BannerLogo />
    <v-row>
      <v-col cols="12">
        <div>
          <!-- Nav tabs -->
          <ul v-if="certificateLogin" class="nav nav-tabs" role="tablist">
            <li role="tab" :class="activeTab == 'username' ? 'active' : ''">
              <a
                @click="activeTab = 'username'"
                aria-controls="usuario"
                role="tab"
                data-toggle="tab"
                title="ENTRAR CON USUARIO"
                >ENTRAR CON USUARIO</a
              >
            </li>
            <li role="tab" :class="activeTab == 'certificate' ? 'active' : ''">
              <a
                @click="activeTab = 'certificate'"
                aria-controls="certificado"
                role="tab"
                data-toggle="tab"
                title="ENTRAR CON CERTIFICADO"
                >ENTRAR CON CERTIFICADO</a
              >
            </li>
          </ul>

          <LoginUsername v-if="activeTab == 'username'" />
          <LoginCert v-if="activeTab == 'certificate'" />
        </div>

        <!--p class="legal">
          Al ingresar aceptas las
          <a href="#" title="Condiciones">Condiciones</a> y confirmas que has
          leído la <a href="#" title="Condiciones">Política de datos</a> y el
          <a href="#" title="Condiciones">Uso de cookies</a>.
        </p-->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BannerLogo from "@/components/BannerLogo.vue";
import LoginCert from "@/components/LoginCert";
import LoginUsername from "@/components/LoginUsername";

export default {
  name: "Login",
  components: {
    BannerLogo,
    LoginCert,
    LoginUsername
  },
  data() {
    return { activeTab: "username", certificateLogin: false };
  }
};
</script>
<style scoped>
.tabs,
.legal {
  z-index: 1;
}
</style>
