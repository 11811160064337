<template>
  <div>
    <h3 class="text-h3" v-if="currentUser">
      Welcome in, {{ currentUser.first_name }}
    </h3>
    <div v-if="stats">
      <h4 class="text-subtitle-1">Here are some basic stats:</h4>
      <pre
        >{{ stats }}
      </pre>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Backend from "@/services/Backend";

export default {
  data() {
    return {
      stats: null
    };
  },
  computed: {
    ...mapState("accounts", ["currentUser"])
  },
  async created() {
    let response = await Backend.getStats();
    this.stats = response.data;
  }
};
</script>
