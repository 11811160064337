import Vue from "vue";
import VueOnClickout from "vue-on-clickout";

import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";

//import "@/assets/css/bootstrap.min.css";
//import "@/assets/css/styles.css";

Vue.config.productionTip = false;

Vue.use(VueOnClickout);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
