import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";

Vue.use(VueRouter);

const idAsNumber = route => ({
  id: parseInt(route.params.id)
});

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    // Only routes labelled as public do not require logging in
    meta: {
      public: true
    }
  },
  {
    path: "/users",
    name: "users",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue")
  },
  {
    path: "/users/:id",
    name: "user",
    props: idAsNumber,
    component: () => import(/* webpackChunkName: "user" */ "../views/User.vue")
  },
  {
    path: "/associations",
    name: "associations",
    component: () =>
      import(/* webpackChunkName: "associations" */ "../views/Associations.vue")
  },
  {
    path: "/associations/:id",
    name: "association",
    props: idAsNumber,
    component: () =>
      import(/* webpackChunkName: "association" */ "../views/Association.vue")
  },
  {
    path: "/plans",
    name: "plans",
    component: () =>
      import(/* webpackChunkName: "plans" */ "../views/PricingPlans.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export const processLoggedInState = (to, from, next) => {
  const isLoggedIn = store.getters["accounts/isLoggedIn"];
  const isPrivate = to.matched.some(record => !record.meta.public);
  const isLoginPage = to.name == "login";

  if (isPrivate && !isLoggedIn) {
    // remember route to go once logged in:
    store.dispatch("routing/setNextRoute", to);
    next({ name: "login" });
  } else if (isLoggedIn && isLoginPage) {
    next({ name: "home" });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => processLoggedInState(to, from, next));

export default router;
